import React from 'react';
import '../App.css';
import logo from '../assets/GT-Logo.png';
import albumArtwork from '../assets/C\'EST LA VIE ARTWORK IDEA.png';

function Home() {
  return (
    <div className="home-container">
      <img src={logo} alt="Globetrotters Logo" className="logo" />
     
      <h1>C'est La Vie</h1>
      <p><strong>Produced By</strong> Paris Williams & Kaleab Samuel</p>
      <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/BcUqJpzW3qs"
          title="C'est La Vie"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* Music Embeds Container */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '560px',
        margin: '20px auto'
      }}>
        {/* Spotify Embed */}
        <iframe 
          style={{
            borderRadius: '12px', 
            marginBottom: '20px',
            width: '90%',
            height: '280px'
          }} 
          src="https://open.spotify.com/embed/album/4DcUe1mkeB17acbLAojrtO?utm_source=generator" 
          frameBorder="0" 
          allowFullScreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy"
        ></iframe>
      
      </div>


      <p><strong>Clean Version for DJs</strong></p>


      <a
        href="https://mega.nz/file/LdAX3Dia#jiv0K9OZfWJo_TKqcO5q3AgTddh6pQlkQLZFTQqtGuM"
        className="download-button"
        download
      >
        Download
      </a>
    </div>
  );
}

export default Home;
